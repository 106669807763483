import { isAfter, isBefore, isValid } from 'date-fns';

// Import variables
import { PRODUCT_TYPES } from 'sitemap/helpers/variables';
import { NO_PROGRAM } from 'helpers/variables';

const { CHANNEL } = PRODUCT_TYPES;

const isDateValid = (date) => (date ? isValid(new Date(date)) : false);

const checkIsWithinTimePeriod = ({ since, till }) => {
	const isValidDates = isDateValid(since) && isDateValid(till);

	if (isValidDates) {
		const now = new Date();

		const isCurrentDateInRange = isAfter(now, since) && isBefore(now, till);

		return isCurrentDateInRange;
	}

	return false;
};

const findCurrentLiveProgram = (programs) => {
	// Get the index of current live program
	return programs.findIndex((program) => checkIsWithinTimePeriod(program));
};

const getCurrentProgram = ({ channelUuid, channels }) => {
	const channel = channels?.find(({ id }) => id === channelUuid);

	const programs = channel?.epgProgrammes || [];

	const currentLiveIndex = findCurrentLiveProgram(programs);

	return programs[currentLiveIndex];
};

const getChannelWithPrograms = ({ channelUuid, channels }) => {
	const channel = channels?.find(({ id }) => id === channelUuid);

	const programs = channel?.epgProgrammes || [];

	const currentLiveIndex = findCurrentLiveProgram(programs);

	const currentAndNextPrograms = programs
		.slice(currentLiveIndex)
		.filter(({ uuid }) => uuid !== NO_PROGRAM);

	return currentAndNextPrograms?.map((_, index) => ({
		...channel,
		isProgramLive: index === 0,
		currentProgram: currentAndNextPrograms[index]
	}));
};

export const convertSliderItemsData = ({
	sliderItems,
	channels,
	isChannelsCurrentProgramsSection,
	isChannelEpgSection
}) => {
	if (isChannelsCurrentProgramsSection) {
		// RETURNING ONLY CHANNELS
		const channelItems = sliderItems?.filter(({ type }) => type === CHANNEL);

		// ADDING CURRENT PROGRAMS TO CHANNELS
		return channelItems.map((channel) => ({
			...channel,
			isProgramLive: true,
			currentProgram: getCurrentProgram({ channelUuid: channel.uuid, channels })
		}));
	}

	if (isChannelEpgSection) {
		// RETURNING ONLY CHANNELS
		const channel = sliderItems.find(({ type }) => type === CHANNEL);

		return getChannelWithPrograms({
			channelUuid: channel.uuid,
			channels
		});
	}

	return sliderItems;
};
