// Import helpers
import {
	checkProperFavouriteProduct,
	createLinkParams,
	getImage
} from 'helpers';

// Import helpers
import { PRODUCT_IMAGES_TYPES, LINK_TYPES } from 'helpers/variables';
import { getGenreName } from 'helpers/languages';

// Import utilities
import {
	useProductAvailability,
	useProductTranslations
} from 'components/utilities';
import useImageCheck from '../useImageCheck';
import { displayNotificationButton } from './helpers';
// Import assets
import placeholder from 'assets/images/logo_wide.png';

const { LOGO, POSTER, COVER_SMALL, COVER } = PRODUCT_IMAGES_TYPES;
const { CHANNEL, COLLECTION } = LINK_TYPES;

const useSliderItem = ({
	data,
	sliderId = '',
	imageType = POSTER,
	isChannelEpgSection,
	isChannelsCurrentProgramsSection,
	isCarousel
}) => {
	const { product } = useProductTranslations({ data });

	const {
		title,
		year,
		country,
		genres,
		uuid,
		subtype,
		previews,
		images,
		available_in,
		currentProgram
	} = product;

	const isAvailable = useProductAvailability(uuid, available_in);

	const isCollectionPage = sliderId === COLLECTION;
	const isChannel = subtype === CHANNEL;
	const backgroundSize = isChannel ? 'scale-down' : 'cover';

	const programCover = currentProgram
		? getImage({
				images: currentProgram?.images,
				type: COVER
		  })
		: null;

	const isSliderItemWithProgram =
		(isCarousel && isChannelEpgSection) ||
		(!isCarousel && (isChannelEpgSection || isChannelsCurrentProgramsSection));

	const imageSrc = isSliderItemWithProgram
		? programCover
		: getImage({ images, type: isChannel ? LOGO : imageType });

	const programChannelLogo = getImage({
		images,
		type: LOGO
	});

	const linkParams = createLinkParams({ id: uuid, type: subtype, title });

	const { isError: isImageError } = useImageCheck(imageSrc);

	const imgSrc = isImageError ? placeholder : imageSrc;

	const channelBackgroundImageSrc =
		isChannel && getImage({ images, type: COVER_SMALL });

	const genre = getGenreName({ genres });

	const metadata = [year, genre, country].filter((meta) => meta);

	const videoAssetId = previews?.preview?.[0]?.videoAssetId;

	const showWatchlistButton = checkProperFavouriteProduct(subtype);

	const isEpgData = isChannelEpgSection || isChannelsCurrentProgramsSection;
	const modifiers =
		isChannelsCurrentProgramsSection && isCarousel
			? 'carouselSliderItemWithChannel'
			: isSliderItemWithProgram
			? 'sliderItemWithProgram'
			: null;

	const { product: channelProduct } = useProductTranslations({
		data: isEpgData ? data?.currentProgram : null
	});

	const shouldDisplayNotificationButton = displayNotificationButton(
		channelProduct,
		isSliderItemWithProgram
	);

	const showInformation =
		!isCarousel && (!isChannel || isSliderItemWithProgram);

	const showChannelLogo = isSliderItemWithProgram && !isChannelEpgSection;

	return {
		isAvailable,
		isCollectionPage,
		linkParams,
		backgroundSize,
		imgSrc,
		channelBackgroundImageSrc,
		programChannelLogo,
		isChannel,
		title,
		uuid,
		subtype,
		metadata,
		videoAssetId,
		showWatchlistButton,
		isSliderItemWithProgram,
		channelProduct,
		modifiers,
		shouldDisplayNotificationButton,
		showInformation,
		showChannelLogo,
		isImageError
	};
};

export default useSliderItem;
