import styled from 'styled-components';
import {
	Button,
	ButtonText
} from 'components/elements/atoms/ButtonRounded/styles.js';

// Icons
import { ReactComponent as BellIcon } from 'assets/icons/ic_bell2.svg';
import { ReactComponent as BellRingingIcon } from 'assets/icons/ic_BellRinging.svg';
import { setSVGColor } from 'components/styles/Mixin';

export const StyledButton = styled(Button)`
	position: relative;
	bottom: -0.4rem;
`;

export const StyledButtonText = styled(ButtonText)`
	position: absolute;
	left: 0;
	text-align: center;
`;

export const StyledTextWrapper = styled.div`
	height: 4rem;
`;

export const StyledBellIcon = styled(BellIcon)`
	${({ theme }) =>
		setSVGColor(theme.section_channels_details.channel.notificationActive)}
`;

export const StyledBellRingingIcon = styled(BellRingingIcon)`
	${({ theme }) =>
		setSVGColor(theme.section_channels_details.channel.notificationInactive)}
`;
