import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

//Import styles
import {
	baseRootStyle,
	borderGradient,
	flex,
	transitionMain
} from 'components/styles';

const BUTTON_MODIFIERS = {
	slider: ({ theme: { buttonRounded } }) => css`
		p {
			font-size: 0.8em;
		}

		${ChildrenWrapper} {
			height: 2.75em;
			width: 2.75em;

			${borderGradient({ borderSize: 2, borderBg: buttonRounded.border })}

			& > svg {
				width: 1.25em;
				height: 1.25em;
			}
		}
	`,

	sliderWatchList: () => css`
		${ChildrenWrapper} {
			& > img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 2em;
				height: 2em;
				object-fit: contain;
			}
		}
	`,

	userWatchList: ({ theme: { buttonRounded } }) => css`
		p {
			font-size: 0.4em;
		}
		row-gap: 0.5rem;

		${ChildrenWrapper} {
			height: 1em;
			width: 1em;

			${borderGradient({ borderSize: 1, borderBg: buttonRounded.border })}

			& > svg {
				width: 0.6em;
				height: 0.6em;
			}
		}
	`,

	userWatchListButton: () => css`
		${ChildrenWrapper} {
			& > img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 0.8em;
				height: 0.8em;
				object-fit: contain;
			}
		}
	`
};

export const Button = styled.div`
	position: relative;
	color: ${({ theme }) => theme.buttonRounded.color};
	background-color: transparent;
	border: none;
	cursor: pointer;
	${transitionMain()}
	${flex({ fd: 'column' })}
	row-gap: 1rem;
	z-index: 0;

	&:hover,
	&:focus {
		transform: scale(1.2);
	}

	${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export const ButtonText = styled.p`
	height: 2rem;
	margin-bottom: 1.5rem;
	font-size: 1.5rem;
	font-weight: ${({ theme }) => theme.font.regular};
`;

export const ChildrenWrapper = styled.div`
	${baseRootStyle}
	border-radius: ${({ theme }) => theme.buttonRounded.roundSize}rem;
	background-color: ${({ theme }) => theme.buttonRounded.bg};
	${transitionMain()} 

	${({ theme }) =>
		css`
			${borderGradient({ borderSize: 3, borderBg: theme.buttonRounded.border })}
		`};

	& > svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
