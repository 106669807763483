import React from 'react';
import { number, string, oneOfType, bool, func } from 'prop-types';
import styled, { css } from 'styled-components';
import { Heading } from 'components/elements';
// COMPONENTS
import { VodSectionButton } from '../../../components/buttons/VodSectionButton';
import { LiveIndicator } from 'components/views/vod/components/sliders/LiveIndicator/LiveIndicator';

// Styles
import { rtl } from 'components/styles';

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	${rtl`
  padding: 0 4.5rem 0 8.2rem;
  `}

	${({ borderLine }) =>
		borderLine &&
		css`
			border-bottom: 1px solid rgba(238, 238, 238, 0.22);
			padding: 0.5rem 4.5rem 2rem;
		`}
`;

const Title = styled(Heading.First)`
	color: ${({ theme }) => theme.slider.name};
	font-size: 2.2rem;
	line-height: 3rem;
	margin: 0;
	z-index: 2;
	font-weight: ${({ theme }) => theme.font.semiMedium};
`;

export const RegularHeader = ({
	title,
	sectionId,
	showAllMoviesButton,
	borderLine = false,
	isChannelSection = false,
	saveSectionId = () => {},
	liveIndicator = false
}) => {
	return (
		<Container borderLine={borderLine}>
			{liveIndicator ? (
				<LiveIndicator />
			) : (
				<>
					<Title>{title}</Title>
					{showAllMoviesButton && (
						<VodSectionButton
							sectionId={sectionId}
							sectionTitle={title}
							isChannelSection={isChannelSection}
							saveSectionId={saveSectionId}
						/>
					)}
				</>
			)}
		</Container>
	);
};

RegularHeader.propTypes = {
	title: string.isRequired,
	sectionId: oneOfType([number, string]).isRequired,
	showAllMoviesButton: bool.isRequired,
	borderLine: bool,
	isChannelSection: bool,
	saveSectionId: func
};
