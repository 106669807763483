import styled from 'styled-components';
import { RemindersMenu } from 'components/elements/molecules/popover/common/RemindersMenu';
import { ReactComponent as BellIcon } from 'assets/icons/ic_bell2.svg';
import { ReactComponent as BellRingingIcon } from 'assets/icons/ic_BellRinging.svg';
import {
	Button,
	ButtonText
} from 'components/elements/atoms/ButtonRounded/styles';
import { setSVGColor } from 'components/styles/Mixin';

export const StyledRemindersMenu = styled(RemindersMenu)`
	position: absolute;
	bottom: 0;
	right: 0;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s;
`;

export const StyledWrapper = styled.div`
	position: relative;
	flex-shrink: 0;

	&:hover ${StyledRemindersMenu} {
		display: block;
		opacity: 1;
		pointer-events: all;
	}
`;

export const StyledTextWrapper = styled.div`
	height: 4rem;
`;

export const StyledBellIcon = styled(BellIcon)`
	${({ theme }) =>
		setSVGColor(theme.section_channels_details.channel.notificationActive)}
`;

export const StyledBellRingingIcon = styled(BellRingingIcon)`
	${({ theme }) =>
		setSVGColor(theme.section_channels_details.channel.notificationInactive)}
`;

export const StyledButton = styled(Button)`
	position: relative;
	bottom: -0.4rem;
`;

export const StyledButtonText = styled(ButtonText)`
	${({ isTransformedText }) => isTransformedText && 'transform:scale(0.8);'}
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
`;
