import React, { useMemo } from 'react';
import { withTheme } from 'styled-components';
import { string, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { deleteReminder } from 'store/actions';
import { useAuth } from 'components/utilities';
// Import components
import { AuthModal } from 'components/elements';
import { ChildrenWrapper } from 'components/elements/atoms/ButtonRounded/styles';
import i18n from 'i18next';

// Import styles
import {
	StyledRemindersMenu,
	StyledWrapper,
	StyledBellIcon,
	StyledBellRingingIcon,
	StyledButton,
	StyledButtonText,
	StyledTextWrapper
} from './styles';
import { useTranslation } from 'react-i18next';

const { language } = i18n;
const CardProgramReminder = ({ programId, startDate, modifiers, theme }) => {
	const dispatch = useDispatch();
	const reminders = useSelector((store) => store.reminders.data);
	const { t } = useTranslation();
	const matchedReminder = reminders.find(
		({ product_uuid }) => product_uuid === programId
	);
	const { isAuth } = useAuth();

	const shouldTransformText = (matchedReminder) =>
		language === 'en' && matchedReminder;

	const handleDeleteReminder = (e) => {
		if (matchedReminder.id) {
			deleteReminder(matchedReminder?.id)(dispatch);
			e.currentTarget.blur();
		}
	};
	const isTransformedText = useMemo(
		() => shouldTransformText(matchedReminder),
		[matchedReminder]
	);
	const title = matchedReminder
		? t('common_section_buttons_notifcation_delete')
		: t('common_section_buttons_notifcation_add');

	const Icon = matchedReminder ? StyledBellIcon : StyledBellRingingIcon;

	return (
		<AuthModal>
			<StyledWrapper>
				<StyledButton
					padding="0"
					modifiers={['slider']}
					title={title}
					onClick={handleDeleteReminder}
				>
					<ChildrenWrapper width={80} height={80}>
						<Icon height="35px" width="35px" />
					</ChildrenWrapper>
					<StyledTextWrapper>
						<StyledButtonText isTransformedText={isTransformedText}>
							{title}
						</StyledButtonText>
					</StyledTextWrapper>
				</StyledButton>
				{!matchedReminder && isAuth && (
					<StyledRemindersMenu programId={programId} startDate={startDate} />
				)}
			</StyledWrapper>
		</AuthModal>
	);
};

CardProgramReminder.propTypes = {
	programId: string,
	startDate: string,
	theme: object,
	variant: string
};

export default withTheme(CardProgramReminder);
