import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// Import helpers
import { convertSliderItemsData } from './helpers';
import { shuffledArray } from 'helpers';
import { PRODUCT_TYPES } from 'sitemap/helpers/variables';

// Import utilities
import { useInterval } from 'components/utilities';

const { CHANNEL } = PRODUCT_TYPES;

export const useSliderItems = ({
	itemsList,
	shuffle,
	isEpg,
	itemsProcessingDisabled
}) => {
	const [currentDate, setCurrentDate] = useState(new Date());

	const channels = useSelector(({ channels }) => channels.data.lives);

	const channelsLength = useMemo(
		() => itemsList.filter((item) => item.type === CHANNEL).length,
		[itemsList]
	);

	const isChannelEpgSection = isEpg && channelsLength === 1;
	const isChannelsCurrentProgramsSection = isEpg && channelsLength >= 2;

	const isSectionRequireRefreshing =
		(isChannelEpgSection || isChannelsCurrentProgramsSection) &&
		!itemsProcessingDisabled;

	const shouldShuffle =
		shuffle && !isChannelEpgSection && !itemsProcessingDisabled;

	// SHUFFLING SLIDER ITEMS IF IS REQUIRED
	const sliderItems = useMemo(
		() => (shouldShuffle ? shuffledArray(itemsList) : itemsList),
		[itemsList, shouldShuffle]
	);

	useInterval(
		() => setCurrentDate(new Date()),
		isSectionRequireRefreshing ? 60 * 1000 : null
	);

	return useMemo(
		() => ({
			sliderItems: itemsProcessingDisabled
				? sliderItems
				: convertSliderItemsData({
						sliderItems,
						channels,
						isChannelsCurrentProgramsSection,
						isChannelEpgSection
				  }),
			isChannelEpgSection,
			isChannelsCurrentProgramsSection
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentDate, itemsList, isChannelsCurrentProgramsSection, channels]
	);
};
