import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
	setTvProgramsLive,
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	playerOnCancel
} from 'store/actions';
import { useHistory } from 'react-router-dom';

// Import variables
import { PLAYER_SELECTORS } from 'helpers/variables';
import LogoWide from 'assets/images/logo_wide.png';

// Import helpers
import { APP_PATHS_KEY } from 'components/routes/helpers/paths';
import { setChannelLogo } from 'helpers/index';
import { getNavigationPaths } from 'components/routes/helpers/paths';
import { trimChannelSlug } from 'components/views/tv/helpers';

// Import utilities
import { ImageOnLoadRPC, useProductTranslations } from 'components/utilities';

// Import components
import { AvailableMarks } from 'components/elements';
import SidebarInfo from './SidebarInfo';
import FavouriteButton from './FavouriteButton/FavouriteButton';

// Import styles
import { Channel, Content, Description, Figure, Logo, LogoBox } from './styles';

const { LIVE } = APP_PATHS_KEY;
const SidebarChannel = React.memo(
	({
		// isFullScreen comment temp for eslint
		fetchPlayerConfig,
		deleteVideoSession,
		setSelectedProgram,
		style,
		videoSessionId,
		selectedChannelID,
		playerOnCancel,
		images,
		channelID,
		channelName,
		available,
		program,
		channelTitle
	}) => {
		const { product: programProduct } = useProductTranslations({
			data: program
		});

		const history = useHistory();
		const { title, since, till, uuid: id, pc_rating } = programProduct;

		// Check if program is selected
		const isProgramSelected = channelID === selectedChannelID ? true : false;

		// also change function in Favourite.js
		const handlePlaySelected = async () => {
			// Delete request
			// Dispatch an playerOnCancel action in player folder
			playerOnCancel(PLAYER_SELECTORS.PLAYER);
			// Delete video live session
			// Dispatch an deleteVideoSession action in player folder
			videoSessionId && (await deleteVideoSession());
			// Set title in player overlay content
			// Dispatch an setSelectedProgram action in channel folder
			setSelectedProgram({ title, id, channel: channelName, channelID });
			history.push(
				`${getNavigationPaths(LIVE)}/${channelID}/${trimChannelSlug(
					channelTitle
				)}`
			);
			// Fetch new video session config
			// Dispatch an fetchPlayerConfig action in player folder
			fetchPlayerConfig({ productID: channelID });
		};
		const programSrc = isEmpty(images) ? LogoWide : setChannelLogo(images);

		return (
			<Channel isSelected={isProgramSelected} style={style}>
				<Content onClick={handlePlaySelected}>
					<FavouriteButton channelID={channelID} />
					<LogoBox isSelected={isProgramSelected}>
						<Figure>
							<AvailableMarks
								isAvailable={available}
								isContrast
								modifiers="small"
							/>
							<ImageOnLoadRPC>
								{({ setError }) => (
									<Logo onError={setError} src={programSrc} alt="Logo" />
								)}
							</ImageOnLoadRPC>
						</Figure>
					</LogoBox>
					<Description isSelected={isProgramSelected}>
						<SidebarInfo
							since={since}
							till={till}
							title={title}
							pcRating={pc_rating}
						/>
					</Description>
				</Content>
			</Channel>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

SidebarChannel.propTypes = {
	images: PropTypes.object.isRequired,
	channelName: PropTypes.string.isRequired,
	channelID: PropTypes.string.isRequired,
	nextPrograms: PropTypes.object,
	program: PropTypes.object,
	fetchPlayerConfig: PropTypes.func.isRequired,
	deleteVideoSession: PropTypes.func.isRequired,
	setSelectedProgram: PropTypes.func.isRequired,
	setTvProgramsLive: PropTypes.func.isRequired,
	videoSessionId: PropTypes.string,
	selectedChannelID: PropTypes.string,
	available: PropTypes.bool,
	favourites: PropTypes.array,
	isVisible: PropTypes.bool.isRequired,
	isScrolling: PropTypes.bool.isRequired,
	channelTitle: PropTypes.string
};

const mapStateToProps = ({
	player: { videoSessionId },
	selected_program: { channelID }
}) => ({
	videoSessionId,
	selectedChannelID: channelID
});

export default connect(
	mapStateToProps,
	{
		fetchPlayerConfig,
		deleteVideoSession,
		setSelectedProgram,
		setTvProgramsLive,
		playerOnCancel
	}
)(SidebarChannel);
