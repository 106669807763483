import React from 'react';
import { string, number, object, array, shape, func, bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Import utilities
import useSliderItem from './useSliderItem';
// Import components
import { AvailableMarks } from 'components/elements';
import { DetailsButton } from 'components/views/vod/components/buttons/DetailsButton';
import Preview from 'components/views/vod/components/sliders/Carousel/components/Info/preview/Preview';
import WatchlistButton from 'components/views/vod/components/buttons/WatchlistButton';
import { ProgramInfo } from './ProgramInfo';
import CardProgramReminder from 'components/views/tv/program_details/program_toolbar/CardProgramReminder/CardProgramReminder';

// Import styles
import {
	Wrap,
	StyledLink,
	Information,
	Grid,
	MetaItem,
	StyledImage,
	ProgramChannelLogo,
	ProgramInfoSliderItem
} from './styles';

const SliderItem = ({
	data,
	sliderId,
	imageType,
	width,
	height,
	onClick,
	isCarousel = false,
	saveSectionId = () => {},
	isActive,
	isChannelEpgSection = false,
	isChannelsCurrentProgramsSection = false
}) => {
	const { isRtl, direction } = useSelector(({ direction }) => direction);
	const {
		isAvailable,
		isCollectionPage,
		linkParams,
		backgroundSize,
		imgSrc,
		channelBackgroundImageSrc,
		programChannelLogo,
		isChannel,
		title,
		uuid,
		subtype,
		metadata,
		videoAssetId,
		showWatchlistButton,
		isSliderItemWithProgram,
		shouldDisplayNotificationButton,
		channelProduct,
		modifiers,
		showInformation,
		showChannelLogo,
		isImageError
	} = useSliderItem({
		data,
		sliderId,
		imageType,
		isChannelEpgSection,
		isChannelsCurrentProgramsSection,
		isCarousel
	});

	const renderAs = onClick ? 'button' : Link;
	const handleItemClick = () => (onClick ? onClick() : saveSectionId());
	const imageWidth = isChannel ? '60%' : width;
	const imageHeight = isChannel ? '' : height;
	const showItemActive = isCarousel && isActive;

	return (
		<Wrap
			backgroundSize={backgroundSize}
			isChannel={isChannel}
			channelBackgroundImageSrc={channelBackgroundImageSrc}
			showInformation={showInformation}
			width={width}
			height={height}
			showItemActive={showItemActive}
			modifiers={modifiers}
			imgSrc={imgSrc}
			isImageError={isImageError}
		>
			<StyledLink
				onClick={handleItemClick}
				to={linkParams}
				as={renderAs}
				isSliderItemWithProgram={isSliderItemWithProgram}
			>
				<StyledImage
					width={imageWidth}
					height={imageHeight}
					src={imgSrc}
					effect="blur"
					alt={title}
				/>

				{!isCollectionPage && <AvailableMarks isAvailable={isAvailable} />}

				{showChannelLogo && <ProgramChannelLogo src={programChannelLogo} />}
			</StyledLink>
			{(!isChannel || isSliderItemWithProgram) && (
				<Information className="information" width={width}>
					{isCarousel ? (
						<Grid gap={2.7} dir={direction}>
							{metadata.map((info) => (
								<MetaItem key={info} isRtl={isRtl}>
									{info}
								</MetaItem>
							))}
						</Grid>
					) : (
						<>
							{isSliderItemWithProgram && (
								<ProgramInfoSliderItem program={channelProduct} />
							)}
							<Grid gap={3} dir={direction}>
								<DetailsButton
									title={title}
									uuid={uuid}
									subtype={subtype}
									modifiers="slider"
								/>

								{!!videoAssetId && (
									<Preview
										uuid={uuid}
										videoAssetId={videoAssetId}
										modifiers="slider"
									/>
								)}
								{uuid && showWatchlistButton && (
									<WatchlistButton
										uuid={uuid}
										modifiers={['slider', 'sliderWatchList']}
									/>
								)}
								{shouldDisplayNotificationButton && (
									<CardProgramReminder
										modifiers={['slider']}
										programId={channelProduct.uuid}
										startDate={channelProduct.since}
									/>
								)}
							</Grid>
						</>
					)}
				</Information>
			)}

			{isSliderItemWithProgram && <ProgramInfo program={channelProduct} />}
		</Wrap>
	);
};

SliderItem.propTypes = {
	width: number.isRequired,
	height: number.isRequired,
	sliderId: string,
	imageType: string,
	onClick: func,
	isActive: bool,
	isChannelEpgSection: bool,
	isChannelsCurrentProgramsSection: bool,
	isCarousel: bool,
	data: shape({
		uuid: string.isRequired,
		images: object,
		subtype: string.isRequired,
		context: object,
		title: string,
		year: number,
		genres: array,
		rating: number,
		country: string
	}),
	saveSectionId: func
};

export default SliderItem;
