import React from 'react';
import { useTranslation } from 'react-i18next';

// Import assets
import { ReactComponent as LiveIconSVG } from 'assets/icons/ic_live.svg';

// Import styles
import { LiveText } from './styles';

export const LiveIndicator = () => {
	const { t } = useTranslation();

	return (
		<LiveText>
			<LiveIconSVG /> {t('common_live_now')}
		</LiveText>
	);
};
