import React from 'react';

// Import assets
import placeholder from 'assets/images/logo_wide.png';

// Import styles
import { ProgramInfo, Text } from './styles';
import {
	BasicWrap,
	Description,
	Image,
	TitleContainer,
	TitleText,
	ButtonsWrapper
} from '../Info/styles';

// Import components
import { DetailsButton } from 'components/views/vod/components/buttons/DetailsButton';
import { FavouriteButton } from 'components/views/vod/components/buttons/FavouriteButton/FavouriteButton';
import { LiveIndicator } from 'components/views/vod/components/sliders/LiveIndicator/LiveIndicator';
import CarouselProgramReminder from 'components/views/tv/program_details/program_toolbar/CarouselProgramReminder/CarouselProgramReminder';

// Import utilities
import { useProductTranslations } from 'components/utilities';

// Import helpers
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';
import { getImage } from 'helpers';
import { displayNotificationButton } from 'components/views/vod/components/sliders/components/SliderItem/helpers';
import { isEmpty } from 'lodash';

const { LOGO } = PRODUCT_IMAGES_TYPES;

export const ChannelInfo = ({ currentItem }) => {
	const currentProgram = currentItem?.currentProgram;

	const { product } = useProductTranslations({
		data: currentProgram
	});

	const titleImage = getImage({
		images: currentItem?.images,
		type: LOGO
	});

	const title = product?.title;
	const desctiption = product?.summary_short;
	const basicData = [product?.date, product?.category, product?.country]
		.filter(Boolean)
		.join(' | ');
	const isProgramLive = currentItem?.isProgramLive;
	const channelProduct = currentItem?.currentProgram;

	const shouldDisplayNotificationButton = displayNotificationButton(
		channelProduct,
		!isEmpty(currentItem.epgProgrammes)
	);

	return (
		<>
			<TitleContainer>
				<Image titleImage={titleImage || placeholder} />
			</TitleContainer>

			<TitleText>{title}</TitleText>

			{isProgramLive && <LiveIndicator />}

			<BasicWrap>
				<ProgramInfo>
					<Text>{basicData}</Text>
					<FavouriteButton uuid={currentItem?.uuid} />
				</ProgramInfo>
			</BasicWrap>

			<Description ellipsis={{ rows: 3, expandable: true }}>
				{desctiption}
			</Description>
			<ButtonsWrapper>
				<DetailsButton
					title={currentItem?.title}
					uuid={currentItem?.uuid}
					subtype={currentItem?.subtype}
				/>
				{shouldDisplayNotificationButton && (
					<CarouselProgramReminder
						programId={currentItem?.currentProgram.uuid}
						startDate={currentItem?.currentProgram.since}
					/>
				)}
			</ButtonsWrapper>
		</>
	);
};
