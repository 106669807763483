import React from 'react';
import styled from 'styled-components';
import { number, bool, arrayOf, object } from 'prop-types';

// Import assets
import LogoWide from 'assets/images/logo_big.png';

// HELPERS
import { CONTAINER_SCREEN_PERCENT } from './helpers';
import { getImage } from 'helpers';
import { coverImage } from '../../../helpers/coverImage';
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';
import useImageCheck from '../components/useImageCheck';

const { COVER } = PRODUCT_IMAGES_TYPES;

export function BackgroundAndPreview({ content, currentIndex, isEpg }) {
	const currentProgram = content?.[currentIndex]?.currentProgram;

	const channelCover = isEpg
		? getImage({
				images: currentProgram?.images,
				type: COVER
		  })
		: null;

	const { isError: isChannelCoverError } = useImageCheck(channelCover);

	const channelCoverImage =
		isEpg && isChannelCoverError ? LogoWide : channelCover;

	return content?.map((item, index) => {
		const shouldBeShown = currentIndex === index;

		return (
			<Cover
				key={`${item.uuid}${item?.currentProgram?.uuid || ''}`}
				cover={channelCoverImage || coverImage(item.images)}
				isChannel={!!channelCover}
				shouldBeShown={shouldBeShown}
			/>
		);
	});
}

BackgroundAndPreview.propTypes = {
	content: arrayOf(object),
	currentIndex: number,
	isEpg: bool
};

const Cover = styled.div.attrs(({ cover, isChannel }) => ({
	style: {
		backgroundImage: cover
			? `url(${cover}${isChannel ? '' : '=w1920-h1080-c'})`
			: 'none'
	}
}))`
	width: 100%;
	height: 100%;
	max-height: ${CONTAINER_SCREEN_PERCENT}vh;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: ${({ isChannel }) => (isChannel ? 'right' : 'center')};
	background-size: ${({ isChannel }) => (isChannel ? 'contain' : 'cover')};
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	opacity: ${({ shouldBeShown }) => (shouldBeShown ? '1' : '0')};
`;
