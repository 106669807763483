import React from 'react';
import { string, object } from 'prop-types';
import { Dropdown } from 'antd';
import { useDispatch } from 'react-redux';

// Import icons
import { ReactComponent as BellIcon } from 'assets/icons/new_ic_bell.svg';

// Import components
import { Button } from 'components/elements';
import { RemindersMenu } from '../../common/RemindersMenu';

// Import actions
import { deleteReminder } from 'store/actions';

const Reminder = ({ reminderId, theme, programId, startDate }) => {
	const dispatch = useDispatch();

	return reminderId ? (
		<Button onClick={() => deleteReminder(reminderId)(dispatch)}>
			<BellIcon stroke={theme.popover.iconActive} />
		</Button>
	) : (
		<Dropdown
			trigger={['hover']}
			overlay={() => (
				<RemindersMenu programId={programId} startDate={startDate} />
			)}
		>
			<Button>
				<BellIcon stroke={theme.popover.icon} />
			</Button>
		</Dropdown>
	);
};

Reminder.propTypes = {
	reminderId: string.isRequired,
	theme: object.isRequired,
	programId: string.isRequired,
	startDate: string.isRequired
};

export default Reminder;
