import React from 'react';
import { array, bool, number, string } from 'prop-types';
import styled from 'styled-components';

// COMPONENTS
import { BackgroundAndPreview } from './BackgroundAndPreview';
import { Slider } from './components/Slider';
import { Info } from './components/Info';
import GradientBg from 'components/views/vod/components/GradientBg';
import { ErrorBoundary } from 'components/elements';
import { ChannelInfo } from './components/Channel/ChannelInfo';

// HOOKS
import { useCarousel } from './useCarousel';

// HELPERS
import { CONTAINER_SCREEN_PERCENT } from './helpers';

// Import styles
import { rtl } from 'components/styles';

const Root = styled.div`
	position: relative;
	padding-top: 8vh;
	background-color: ${({ theme }) => theme.carousel.bg};
	min-height: 100vh;
	scroll-margin-top: ${({ theme }) => theme.nav.height}rem;
`;

const InfoWrapper = styled.div`
	width: 40vw;
	max-width: 1100px;
	min-width: 45rem;
	position: relative;
	${rtl`
		margin-left: 9rem;
	`}
	z-index: 4;
`;

const SliderWrapper = styled.div`
	position: relative;
	z-index: 4;
`;

export function Carousel({
	itemsList,
	kids = false,
	id,
	name,
	shuffle = false,
	isEpg = false
}) {
	const {
		currentIndex,
		changeCurrentIndex,
		isRtl,
		isChannelEpgSection,
		isChannelsCurrentProgramsSection,
		sliderItems,
		saveSectionId,
		restorationRef
	} = useCarousel({
		itemsList,
		shuffle,
		isEpg,
		id
	});

	const currentItem = sliderItems?.[currentIndex];

	const itemResolutionWidth = isEpg ? 300 : 6;
	const itemResolutionHeight = isEpg ? 170 : 9;
	const itemsToShow = isEpg ? 6.5 : 8;

	return (
		<Root
			id="carousel_slider"
			isSeries={currentItem?.subtype === 'series'}
			ref={restorationRef}
		>
			{sliderItems.length > 0 && (
				<>
					<BackgroundAndPreview
						content={sliderItems}
						currentIndex={currentIndex}
						isEpg={isEpg}
					/>
					<GradientBg
						containerScreenPercent={CONTAINER_SCREEN_PERCENT}
						isRtl={isRtl}
					/>
					<InfoWrapper>
						{isEpg ? (
							<ChannelInfo currentItem={currentItem} />
						) : (
							<Info
								kids={kids}
								currentItem={currentItem}
								saveSectionId={saveSectionId}
							/>
						)}
					</InfoWrapper>
					<SliderWrapper>
						<ErrorBoundary>
							<Slider
								itemsList={sliderItems}
								changeCurrentIndex={changeCurrentIndex}
								currentIndex={currentIndex}
								id={id}
								name={name}
								shuffle={shuffle}
								isCarousel
								itemResolutionWidth={itemResolutionWidth}
								itemResolutionHeight={itemResolutionHeight}
								itemsToShow={itemsToShow}
								isChannelEpgSection={isChannelEpgSection}
								isChannelsCurrentProgramsSection={
									isChannelsCurrentProgramsSection
								}
							/>
						</ErrorBoundary>
					</SliderWrapper>
				</>
			)}
		</Root>
	);
}

Carousel.propTypes = {
	itemsList: array.isRequired,
	kids: bool,
	shuffle: bool,
	isEpg: bool,
	id: number,
	name: string
};
