import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import helpers
import { sortChannelsByFavourite } from 'helpers/epg';
import { getFilteredChannels } from './sidebar_channel/helpers/index';
import { getLivePrograms, getFavouriteChannels } from 'helpers/epg';
import { getChannelName } from 'helpers/languages';

// Import components
import SidebarChannel from './sidebar_channel/SidebarChannel';
import { SkeletonSidebarChannelList } from 'components/elements/organisms/player/player_overlay/player_sidebar/sidebar_channels/sidebar_channel/SkeletonSidebarChannel';

// Import styles
import { ListItem, Wrapper } from './styles';

const SidebarChannels = React.memo(
	(props) => {
		const { isFullScreen, lives, favourites, query, category } = props;
		const { isRtl } = useSelector(({ direction }) => direction);
		const { i18n } = useTranslation();
		const [isLoading, setIsLoading] = React.useState(true);
		const { channelID: channelIdSelected } = useSelector(
			(state) => state.selected_program
		);

		const channels = React.useMemo(() => {
			const livesFiltered = lives.filter(getLivePrograms);
			const filterOptions = {
				lives: livesFiltered,
				category,
				query,
				locale: i18n.language
			};

			let filteredChannels =
				category === 'ulubione'
					? getFavouriteChannels(lives, favourites)
					: getFilteredChannels(filterOptions);

			// Sort by favorite
			sortChannelsByFavourite(filteredChannels, favourites);

			return filteredChannels;
			// eslint-disable-next-line
		}, [JSON.stringify(props)]);

		const selectedChannelIndex = React.useMemo(
			() => channels.findIndex((channel) => channel.uuid === channelIdSelected),
			[channels, channelIdSelected]
		);
		// Temporary fix for the issue with the sidebar sorting glitches
		// TODO: remove after refactoring
		useEffect(() => {
			const timer = setTimeout(() => {
				setIsLoading(false);
			}, 500);

			return () => {
				clearTimeout(timer);
			};
		}, []);

		const channelRow = ({ index, style, isScrolling, isVisible }) => {
			const {
				currentLiveIndex,
				title: channelTitle,
				images,
				uuid,
				epgProgrammes,
				avaiable,
				translations
			} = channels[index];

			const channelName = getChannelName({ title: channelTitle, translations });

			return (
				<ListItem key={uuid} style={style}>
					<SidebarChannel
						images={images}
						channelID={uuid}
						channelName={channelName}
						channelTitle={channelTitle}
						program={epgProgrammes[currentLiveIndex]}
						nextProgram={epgProgrammes[currentLiveIndex + 1]}
						available={avaiable}
						isScrolling={isScrolling}
						isVisible={isVisible}
						isFullScreen={isFullScreen}
					/>
				</ListItem>
			);
		};

		return (
			<Wrapper isFullScreen={isFullScreen}>
				{isLoading ? (
					<SkeletonSidebarChannelList className="player-sidebar-list" />
				) : (
					<AutoSizer>
						{({ height, width }) => (
							<List
								className="player-sidebar-list"
								width={width}
								height={height}
								rowCount={channels.length}
								rowHeight={75}
								rowRenderer={channelRow}
								overScanRowCount={2}
								style={{ direction: isRtl }}
								scrollToIndex={selectedChannelIndex}
							/>
						)}
					</AutoSizer>
				)}
			</Wrapper>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

SidebarChannels.propTypes = {
	lives: PropTypes.array.isRequired,
	favourites: PropTypes.array.isRequired
};

const mapStateToProps = ({
	channels: {
		data: { lives }
	},
	favourites: { data }
}) => ({
	lives,
	favourites: data
});

export default connect(mapStateToProps)(SidebarChannels);
