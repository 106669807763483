import getSlug from 'speakingurl';

//Import default language
import { defaultLanguage } from 'helpers/languages';

// Import helpers
import {
	getNavigationSubPaths,
	APP_SUB_PATHS_KEY
} from 'helpers/navigationSubPaths';
import { getNavigationPaths } from 'components/routes/helpers';

// Import variables
import {
	LINK_TYPES,
	DEFAULT_PROJECT_LANGUAGE,
	ARABIC_SLUGS,
	ENGLISH_SLUGS,
	ITEM
} from './variables';

const {
	MOVIES,
	PACKETS,
	PACKET,
	PROGRAM_DETAILS,
	CHANNELS,
	WATCH,
	PAGE,
	LIVE
} = APP_SUB_PATHS_KEY;

// CreateLink params
export const createLinkParams = ({
	id,
	hash = 'program',
	type = 'program',
	title = ''
}) => {
	const slugTitle = getSlug(title);
	const typeName = getNavigationSubPaths(type);

	switch (type) {
		case LINK_TYPES.CHANNEL:
			return {
				pathname: `${getNavigationPaths(LIVE)}/${id}/${slugTitle}`
			};

		case LINK_TYPES.VOD:
			return {
				pathname: `/vod/${getNavigationSubPaths(
					MOVIES
				)}/${ITEM}/${id}/${slugTitle}`
			};

		case LINK_TYPES.SERIES:
		case LINK_TYPES.SEASON:
		case LINK_TYPES.EPISODE:
		case LINK_TYPES.COLLECTION:
			return {
				pathname: `/vod/${typeName}/${ITEM}/${id}/${slugTitle}`
			};

		case LINK_TYPES.PROVISION:
		case LINK_TYPES.MAIN:
			return {
				pathname: `/${getNavigationSubPaths(PACKETS)}/${getNavigationSubPaths(
					PACKET
				)}/${id}/${slugTitle}`
			};

		// program
		default:
			return {
				pathname: `/tv/${getNavigationSubPaths(
					CHANNELS
				)}/${getNavigationSubPaths(PROGRAM_DETAILS)}/${id}`,
				hash
			};
	}
};

export const getWatchPath = ({ uuid, type, isAvailable = true }) => {
	if (isAvailable) {
		const watchSubPaths = getNavigationSubPaths(WATCH);
		const typeSubPaths = getNavigationSubPaths(type);
		return `/vod/${watchSubPaths}/${typeSubPaths}/${ITEM}/${uuid}`;
	}

	return `/${getNavigationSubPaths(PACKETS)}`;
};

// Get search parametr
export const getSearchParametr = (search, paramName) => {
	const searchParams = new URLSearchParams(search);
	return searchParams.get(paramName) || false;
};

// Set search parametr
export const setParametr = (name, value = '') => {
	const searchParams = new URLSearchParams();
	searchParams.set(name, value);
	return searchParams.toString();
};

// required categories to translate search links
const urlTranslationCategories = {
	pl: {
		kanaly: 'channel',
		wszystkie: 'all',
		seriale: 'series',
		filmy: 'vod',
		programy: 'program'
	}
};

// get translated type
export const getTypeTranslation = (type) => {
	const { MOVIES, SERIES, CHANNELS, LIVE } = ARABIC_SLUGS;

	switch (type) {
		case MOVIES:
			return APP_SUB_PATHS_KEY.MOVIES;
		case SERIES:
			return APP_SUB_PATHS_KEY.SERIES;
		case CHANNELS:
			return APP_SUB_PATHS_KEY.CHANNELS;
		case LIVE:
			return APP_SUB_PATHS_KEY.LIVE;
		default:
			return type;
	}
};

// get translated search category
export const getSearchCategory = (type) => {
	const translatedTypes = urlTranslationCategories[defaultLanguage];
	if (defaultLanguage !== DEFAULT_PROJECT_LANGUAGE.AR) {
		return translatedTypes[type];
	}

	return type;
};

// check if there is UUID in URL
export const containsUUID = (string) => {
	const regexID = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
	return regexID.test(string);
};

const paramsUrl = {
	pl: {
		genre: 'gatunek',
		subType: 'typ',
		order: 'kolejnosc',
		orderDir: 'sortowanie',
		created_at: 'data_dodania',
		year: 'rok_produkcji',
		title_org: 'tytul_oryginalny',
		title: 'tytul',
		asc: 'rosnaco',
		desc: 'malejaco'
	}
};

export const getUrlParams = (type) => {
	const translatedParams = paramsUrl[defaultLanguage];

	if (defaultLanguage !== DEFAULT_PROJECT_LANGUAGE.AR) {
		return translatedParams[type];
	}

	return type;
};

export const VOD_PARAMS = {
	genre: getUrlParams('genre'),
	subtype: getUrlParams('subType'),
	order: getUrlParams('order'),
	orderDir: getUrlParams('orderDir')
};

// query order translation to filter category items
export const QUERY_ORDER = {
	created_at: getUrlParams('created_at'),
	year: getUrlParams('year'),
	title_org: getUrlParams('title_org'),
	title: getUrlParams('title')
};

// query order dir translation to filter category items
export const QUERY_ORDER_DIR = {
	asc: getUrlParams('asc'),
	desc: getUrlParams('desc')
};

// Transalte slug into arabic
const slugTranslation = (href) => {
	let slugTranslatedHref = href;

	Object.keys(ENGLISH_SLUGS).forEach((key) => {
		const slug = key.toLowerCase();

		if (href.indexOf(`/${slug}/`) > 0 || href.endsWith(`/${slug}`)) {
			slugTranslatedHref = href.replace(`/${slug}`, `/${ARABIC_SLUGS[key]}`);
		}
	});

	return slugTranslatedHref;
};

// Create canonical link
export const getCanonicalLink = (href, language) => {
	let canonicalLink = href.endsWith(`/${language}`)
		? href.replace(`/${language}`, '/')
		: href.replace(`/${language}/`, '/');

	if (language === 'en') {
		canonicalLink = slugTranslation(canonicalLink);
	}

	return `${process.env.REACT_APP_WEBSITE_URL}${canonicalLink}`;
};

export const getDocumentNavLink = (alias) => {
	const page = getNavigationSubPaths(PAGE);
	const type = getNavigationSubPaths(alias);
	return `/${page}/${type}`;
};
