import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Menu } from 'antd';

// Import actions
import { addReminder } from 'store/actions';

// import helpers
import { menuItems, getMinutesFromNow } from './helpers';

export function RemindersMenu({ programId, startDate, className }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const minutesLeft = getMinutesFromNow(startDate);

	const handleClick = (minutes) => () => {
		addReminder(programId, minutes, startDate)(dispatch);
	};

	return (
		<Menu className={className}>
			{menuItems?.map(({ id, minutes }) =>
				minutesLeft && minutesLeft > minutes ? (
					<Menu.Item key={id} onClick={handleClick(minutes)}>
						{t(`common_reminders_list_${minutes}_before`)}
					</Menu.Item>
				) : null
			)}
		</Menu>
	);
}

RemindersMenu.propTypes = {
	programId: string.isRequired,
	startDate: string.isRequired
};
