import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Import variables
import { CAROUSEL_START_TIMEOUT, CAROUSEL_STOP_TIMEOUT } from './helpers';

// Import hooks
import { useSectionFocus } from 'components/utilities';
import { useSliderItems } from '../hooks/useSliderItems/useSliderItems';

export function useCarousel({ itemsList, shuffle, isEpg, id }) {
	const timeoutRef = useRef({});

	const {
		sliderItems,
		isChannelEpgSection,
		isChannelsCurrentProgramsSection
	} = useSliderItems({
		itemsList,
		shuffle,
		isEpg
	});

	const { isRtl } = useSelector(({ direction }) => direction);

	const lastItemIndex = sliderItems.length - 1;
	const currentIndexInitial = 0;

	const [currentIndex, setCurrentIndex] = useState(currentIndexInitial);
	const [stopped, setStopped] = useState(false);

	const { saveSectionId, restorationRef } = useSectionFocus({ id });

	const getNextIndex = (currentIndex, lastItemIndex) => {
		if (lastItemIndex === 0) return currentIndex;
		const nextIndex = currentIndex + 1;
		if (nextIndex > lastItemIndex) return 0;
		return nextIndex;
	};

	const nextItem = () => {
		const nextIndex = getNextIndex(currentIndex, lastItemIndex);
		setCurrentIndex(nextIndex);
	};

	const changeCurrentIndex = (index) => {
		clearTimeout(timeoutRef.current);
		setCurrentIndex(index);
	};

	const stopStartCarousel = () => {
		if (stopped) {
			timeoutRef.current = setTimeout(() => {
				nextItem();
			}, CAROUSEL_STOP_TIMEOUT);
		} else {
			clearTimeout(timeoutRef.current);
		}
		setStopped(!stopped);
	};

	const startCarousel = () => {
		timeoutRef.current = setTimeout(() => {
			nextItem();
			setStopped(false);
		}, CAROUSEL_START_TIMEOUT);
	};

	const setCarouselTimeout = () => {
		if (!isChannelEpgSection) {
			timeoutRef.current = setTimeout(() => {
				nextItem();
				setStopped(false);
			}, CAROUSEL_START_TIMEOUT);
		}
	};

	useEffect(() => {
		setCarouselTimeout();

		return () => clearTimeout(timeoutRef.current);
		// eslint-disable-next-line
	}, [currentIndex]);

	return {
		currentIndex,
		changeCurrentIndex,
		stopStartCarousel,
		startCarousel,
		isRtl,
		isChannelEpgSection,
		isChannelsCurrentProgramsSection,
		sliderItems,
		saveSectionId,
		restorationRef
	};
}
