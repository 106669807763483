import React, { useMemo, useCallback } from 'react';
import { string } from 'prop-types';
import { withTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { deleteReminder } from 'store/actions';
import { Dropdown } from 'antd';
import { ChildrenWrapper } from 'components/elements/atoms/ButtonRounded/styles.js';

// Hooks & Utilities
import { useAuth } from 'components/utilities';
import { useTranslation } from 'react-i18next';

// Components
import { RemindersMenu } from 'components/elements/molecules/popover/common/RemindersMenu';
import { AuthModal } from 'components/elements';
import {
	StyledButton,
	StyledButtonText,
	StyledBellIcon,
	StyledBellRingingIcon,
	StyledTextWrapper
} from './styles';

const CarouselProgramReminder = ({ programId, startDate, theme }) => {
	const dispatch = useDispatch();
	const { isAuth } = useAuth();
	const { t } = useTranslation();
	const reminders = useSelector((store) => store.reminders.data);

	const matchedReminder = useMemo(
		() => reminders.find(({ product_uuid }) => product_uuid === programId),
		[reminders, programId]
	);

	const title = useMemo(
		() =>
			matchedReminder
				? t('common_section_buttons_notifcation_delete')
				: t('common_section_buttons_notifcation_add'),
		[matchedReminder, t]
	);

	const handleDelete = useCallback(() => {
		if (matchedReminder) {
			deleteReminder(matchedReminder.id)(dispatch);
		}
	}, [matchedReminder, dispatch]);

	const renderButtonContent = (IconComponent) => (
		<>
			<ChildrenWrapper width={80} height={80}>
				<IconComponent height="35px" width="35px" />
			</ChildrenWrapper>
			<StyledTextWrapper>
				<StyledButtonText>{title}</StyledButtonText>
			</StyledTextWrapper>
		</>
	);

	if (!isAuth) {
		return (
			<AuthModal>
				<StyledButton padding="0" title={title}>
					{renderButtonContent(StyledBellIcon)}
				</StyledButton>
			</AuthModal>
		);
	}

	if (matchedReminder) {
		return (
			<StyledButton padding="0" title={title} onClick={handleDelete}>
				{renderButtonContent(StyledBellIcon)}
			</StyledButton>
		);
	}

	return (
		<Dropdown
			trigger={['hover']}
			placement="bottomLeft"
			overlay={
				isAuth ? (
					<RemindersMenu programId={programId} startDate={startDate} />
				) : null
			}
		>
			<StyledButton title={title}>
				{renderButtonContent(StyledBellRingingIcon)}
			</StyledButton>
		</Dropdown>
	);
};

CarouselProgramReminder.propTypes = {
	programId: string,
	startDate: string
};

export default withTheme(CarouselProgramReminder);
