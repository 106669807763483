import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import throttle from 'lodash/throttle';

// Import variables
import { DIRECTION_TYPES } from 'helpers/variables';

// Import hooks
import { useSliderItems } from '../hooks/useSliderItems/useSliderItems';

export function useLogic(props) {
	const {
		itemResolutionWidth,
		itemResolutionHeight,
		spaceBetweenItems,
		horizontalPadding,
		verticalPadding,
		headerHeight,
		itemsToShow,
		headerSliderGap,
		renderHeader,
		itemsList,
		shuffle,
		isCarousel,
		isEpg
	} = props;

	const sliderRef = useRef(null);
	const containerRef = useRef(null);
	const isScrolled = useRef(false);

	const { direction } = useSelector((state) => state.direction);

	const isRtl = direction === DIRECTION_TYPES.RTL;

	const [containerWidth, setContainerWidth] = useState(0);

	const {
		sliderItems,
		isChannelEpgSection,
		isChannelsCurrentProgramsSection
	} = useSliderItems({
		itemsList,
		shuffle,
		isEpg,
		// WE HAVE ALREADY RECEIVED PROCESSED DATA FROM CAROUSEL
		itemsProcessingDisabled: isCarousel
	});

	// SLIDER WIDTH
	const sliderWidth = containerWidth - 2 * horizontalPadding;
	// if no header component header height is set to 0
	const checkedHeaderHeight = !!renderHeader ? headerHeight : 0;

	const sumItemsWidth = sliderWidth - spaceBetweenItems * (itemsToShow - 1);
	const itemWidth = sumItemsWidth / itemsToShow;
	const itemAndSliderHeight =
		(itemWidth / itemResolutionWidth) * itemResolutionHeight;
	const containerHeight =
		itemAndSliderHeight +
		2 * verticalPadding +
		checkedHeaderHeight +
		headerSliderGap;

	const handleSetContainerWidth = () => {
		if (containerRef.current) {
			setContainerWidth(containerRef.current.offsetWidth);
		}
	};

	const throttleHandleResize = throttle(handleSetContainerWidth, 500);

	useEffect(() => {
		handleSetContainerWidth();
	}, [containerRef]);

	useEffect(() => {
		window.addEventListener('resize', throttleHandleResize);

		return () => {
			window.removeEventListener('resize', throttleHandleResize);
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const { current } = sliderRef;

		if (current && !isScrolled.current) {
			isScrolled.current = true;
			const lastIndex = sliderItems.length;
			const index = isRtl ? lastIndex - itemsToShow : 0;
			current.slickGoTo(index, true);
		}
		// eslint-disable-next-line
	}, [containerWidth]);

	return {
		containerRef,
		containerHeight,
		checkedHeaderHeight,
		containerWidth,
		itemAndSliderHeight,
		sliderWidth,
		itemWidth,
		sliderRef,
		sliderItems,
		isChannelEpgSection,
		isChannelsCurrentProgramsSection
	};
}
