import styled from 'styled-components';

// Import styles
import { flex, rtl } from 'components/styles';

export const ProgramInfo = styled.div`
	${flex({ jc: 'flex-start', ai: 'center' })}
	font-size: 1.75rem;
	z-index: 2;
	${rtl`
		margin-right: 2rem;
	`}
`;

export const Text = styled.p`
	${rtl`
		margin-right: 1rem;
	`};
	color: ${({ theme }) => theme.white};
	font-size: 2.1rem;
	line-height: 3.6rem;
`;
