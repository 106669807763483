import React from 'react';
import { object, string } from 'prop-types';

// Import styles
import { ProgramInfoWrapper, ProgramTime, ProgramTitle } from './styles';

// Import helpers
import { convertTime } from 'helpers';

export const ProgramInfo = ({ program, className }) => {
	const programTime = convertTime(program?.since, program?.till);

	return (
		<ProgramInfoWrapper className={className}>
			<ProgramTitle>{program?.title}</ProgramTitle>
			<ProgramTime>{programTime}</ProgramTime>
		</ProgramInfoWrapper>
	);
};

ProgramInfo.propTypes = {
	program: object.isRequired,
	className: string
};
