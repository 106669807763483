import styled, { css } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { borderGradientTransparent, flex, rtl } from 'components/styles';

// Import components
import { ProgramInfo } from '../ProgramInfo';

const SLIDER_WRAPPER_MODIFIERS = {
	sliderItemWithProgram: ({ isImageError, imgSrc }) => css`
		.lazy-load-image-background {
			width: 100% !important;
			position: relative;

			${!isImageError &&
				css`
					&:after {
						position: absolute;
						top: 0;
						left: 0;
						content: '';
						display: block;
						width: 100%;
						height: 100%;
						background-image: ${`url(${imgSrc})`};
						background-size: 100%;
						background-position: center;
						filter: blur(10px);
						z-index: -1;
					}
				`};
		}

		${!isImageError &&
			css`
				${StyledImage} {
					height: 100%;
					object-fit: contain;
					box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.25);
				}
			`}

		${StyledImage} {
			filter: grayscale(0);
		}

		${ProgramChannelLogo} {
			filter: grayscale(0);
		}

		&:hover {
			${Information} {
				padding: 0.5em 1em 1em;
				z-index: 4;
			}

			${Grid} {
				margin-top: 2rem;
				z-index: 4;
			}
		}
	`,
	carouselSliderItemWithChannel: () => css`
		img {
			width: 75%;
			filter: grayscale(0);
		}
	`
};

const metadataDivider = css`
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: -1.39em;
		transform: translateY(-50%);
		width: 0.4em;
		height: 0.4em;
		border-radius: 50%;
		background-color: ${({ theme }) => theme.slider.item.metadata.divider};
	}
`;

export const StyledLink = styled.button`
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	border-radius: inherit;
	border: 2px solid transparent;
	border: none;
	background-color: transparent;
	cursor: pointer;
	${flex()}

	${({ isSliderItemWithProgram }) =>
		isSliderItemWithProgram &&
		css`
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				content: '';
				background: linear-gradient(
					180deg,
					rgba(29, 29, 29, 0) 50.28%,
					#1d1d1d 100%
				);
			}
		`}
`;

export const StyledImage = styled(LazyLoadImage)`
	margin: 0 auto;
`;

export const Information = styled.div`
	${flex({ ai: 'flex-start', jc: 'flex-start', fd: 'column' })}
	opacity: 0;
	position: absolute;
	top: 99%;
	visibility: hidden;
	width: 100%;
	padding: 0.5em 2.5em;
	font-weight: ${({ theme }) => theme.font.semiMedium};
	background-color: ${({ theme }) => theme.slider.item.backgroundColor};
	color: ${({ theme }) => theme.slider.item.color};
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	text-align: center;
`;

export const ProgramInfoWrapper = styled.div`
	${rtl`direction: ltr;`};
	position: absolute;
	width: 100%;
	bottom: -4.5rem;
	${flex({ fd: 'column', ai: 'flex-start', jc: 'flex-start' })}
`;

export const Wrap = styled.div`
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	font-size: ${({ width }) => width * 0.05}px;
	border-radius: ${({ isChannel }) => (isChannel ? 1.2 : 0.4)}rem;
	position: relative;
	transition: transform 0.3s ease-in-out;
	${flex()}

	${({ isChannel, showItemActive, theme }) =>
		isChannel &&
		css`
			background: ${theme.slider.item.channelBackgroundColor};
			img {
				filter: grayscale(1);
			}

			${showItemActive &&
				borderGradientTransparent({
					width: 1,
					gradient:
						'linear-gradient(110.42deg, #B07F27 26.61%, #E6CF5D 48.71%, #B07F27 71.75%)'
				})};

			&::before {
				z-index: 1;
			}
		`};

	&:hover {
		background-color: ${({ theme }) => theme.slider.item.backgroundColor};
		border-radius: 1rem;
		filter: ${({ theme }) => theme.slider.item.shadow};
		transform: scale(1.2) !important;
		z-index: 10;

		${ProgramInfoWrapper} {
			z-index: -1;
		}

		${({ isChannel, channelBackgroundImageSrc }) =>
			isChannel &&
			css`
				${channelBackgroundImageSrc &&
					css`
						background: ${`url(${channelBackgroundImageSrc}) no-repeat center/cover`};
					`};
				img {
					filter: grayscale(0);
				}
			`}

		${({ showInformation }) =>
			showInformation
				? css`
						border-bottom-right-radius: 0;
						border-bottom-left-radius: 0;
						border-bottom: none;

						${Information} {
							border-bottom-right-radius: 1rem;
							border-bottom-left-radius: 1rem;
							opacity: 1;
							visibility: visible;
							border: ${({ theme }) => theme.slider.item.border};
							&:before {
								content: '';
								position: absolute;
								left: 0;
								top: -5px;
								width: 100%;
								height: 10px;
								background-color: ${({ theme }) =>
									theme.slider.item.backgroundColor};
							}
						}

						${StyledLink} {
							border: ${({ theme }) => theme.slider.item.border};

							&:after {
								content: '';
								position: absolute;
								left: 0;
								top: 0;
								width: 100%;
								height: 100%;
								background-image: ${({ theme }) =>
									theme.slider.item.hoverGradient};
							}
						}
				  `
				: css`
						${StyledLink} {
							border: ${({ theme }) => theme.slider.item.border};
						}
				  `}
	}

	img {
		object-fit: ${({ backgroundSize }) => backgroundSize};
		object-position: center center;
	}

	${applyStyleModifiers(SLIDER_WRAPPER_MODIFIERS)};
`;

export const ProgramInfoSliderItem = styled(ProgramInfo)`
	position: static !important;
`;

const programText = css`
	direction: ltr;
	display: block;
	color: ${({ theme }) => theme.white};
	font-weight: ${({ theme: { font } }) => font.normal};
	font-size: 1.4rem;
`;

export const ProgramTitle = styled.p`
	${programText}
`;

export const ProgramTime = styled.p`
	${programText}
`;

export const ProgramChannelLogo = styled.img`
	position: absolute;
	width: 4.2rem !important;
	bottom: 1rem;
	left: 1rem;
	z-index: 1;
`;

export const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: ${({ gap }) => gap}em;
	width: 100%;

	&:first-of-type {
		margin-bottom: 1.15em;
	}
`;

export const MetaItem = styled.span`
	position: relative;

	${({ isRtl }) =>
		isRtl
			? css`
					&:not(:first-of-type) {
						${metadataDivider}
					}
			  `
			: css`
					&:not(:last-of-type) {
						${metadataDivider}
					}
			  `}
`;
